import React from 'react'

const Borrow = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '250px 0',
      }}
    >
      <h1>Comming Soon</h1>
    </div>
  )
}

export default Borrow
